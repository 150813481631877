import Vue from 'vue'
// TODO MYTEST pre_requests 需要延后加载并执行，接口预加载转场时才会用到
import 'public/src/pre_requests/index'
import { createRouter } from './router'
import { createStore } from './store/index'
import { sync } from 'vuex-router-sync'
import App from './App.vue'

/**
 *
 * @param {渲染需要的上下文} context
 * @param {客户端或者服务端分别 传入的router、minx、func} params
 */
function createApp (context = {}, params) {
  const router = createRouter(context, params)
  const store = createStore(context)
  const { mixin } = params
  // mshePrefetch.watch(router)
  // 同步路由状态(route state)到 store
  sync(store, router)
  // eslint-disable-next-line no-new
  const app = new Vue({
    mixins: mixin ? [mixin] : [],
    render: h => h(App),
    store,
    router
  })
  return {
    app,
    router,
    store
  }
}

export default createApp
