import schttp from 'public/src/services/schttp'

/**
 * 获取 Dashboard 聚合接口信息
 */
export function fetchDashboardBaseData({ fetchQueue = [], params = {} } = {}, ) {
  return schttp({
    url: '/api/user/dashboard/base/get',
    method: 'POST',
    data: {
      ...params,
      fetchQueue,
      clientTime: Date.now(),
    }
  })
}

/**
 * 获取用户信息
 */
export function fetchAccInfo() {
  return schttp({
    url: '/api/user/account_setting/getAccInfo/get',
  }).then(result => {
    if(result?.code == 300206) {
      gbCommonInfo.user = {
        email: ''
      }
    }
    return result
  })
}
