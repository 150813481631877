<template>
  <a
    :data-href="bagInfo.href"
    class="j-header-bag j-footer-cart ft-li waves-btn waves-btn-auto waves-effect waves-classic"
    :class="bagInfo.class"
    DA-type="syncClick"
    DA-category="导航栏"
    DA-action="ClickBag"
    tabindex="0"
    role="link"
    :aria-label="bagInfo.label"
    @click="onClick"
  >
    <template v-if="bagInfo.defaultImgSrc && bagInfo.selectedImgSrc">
      <div class="index-footer__ccc-skin-warp">
        <div
          tabindex="-1"
          aria-hidden="true"
          role="none"
          class="index-footer__ccc-skin-icon"
          :style="{
            backgroundImage: `url(${bagInfo.defaultImgSrc})`
          }"
        ></div>
        <div
          tabindex="-1"
          aria-hidden="true"
          role="none"
          class="index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active"
          :style="{
            backgroundImage: `url(${bagInfo.selectedImgSrc})`
          }"
        ></div>
      </div>
    </template>
    <template v-else>
      <i
        class="tabbar-icon"
      >
        <sui_icon_tab_cart_30px
          size="26px"
          :color="IS_RW ? '#bac5c7':'#767676'"
        />
      </i>
      <i
        class="tabbar-icon_active"
      >
        <sui_icon_tab_cart_selected_30px
          size="26px"
          :color="IS_RW ? '#FC4070':'#222'"
        />
      </i>
    </template>

    <transition
      :name="transitionName"
      @after-enter="afterEnter"
    >
      <span
        v-if="current === 'freeshipping'"
        key="free"
        class="footer__tag free"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="10"
          viewBox="0 0 11 10"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.70418 1.95992V0.5H0.505127V2.82253V5.64669V7.72576H1.38061C1.61999 6.88755 2.39173 6.27393 3.30679 6.27393C4.22185 6.27393 4.99359 6.88755 5.23297 7.72576H5.80847C6.04786 6.88755 6.81959 6.27393 7.73465 6.27393C8.6497 6.27393 9.42144 6.88754 9.66083 7.72574H10.2849V2.8036L7.70418 1.95992ZM7.70418 4.72558V3.01201L9.28455 3.52858V4.72558H7.70418Z"
            fill="white"
          />
          <circle
            cx="7.7583"
            cy="8.27686"
            r="1"
            fill="white"
          />
          <circle
            cx="3.30688"
            cy="8.27686"
            r="1"
            fill="white"
          />
        </svg>
        <span class="footer__text">Free</span>
      </span>
      <span
        v-else-if="current === 'gift'"
        key="gift"
        class="footer__tag gift"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.16068 3.33313L8.33708 2.25969L7.66304 1.521L6.00506 3.03387L4.39965 1.52585L3.715 2.25472L4.86306 3.33313H1.16675L1.16681 6.49976H1.83341V10.9998H10.1667V6.49976H10.8335L10.8334 3.33313H7.16068ZM9.16675 6.49976H2.83341V9.99976H9.16675V6.49976ZM9.83348 5.49976L9.83342 4.33313H2.16675L2.16681 5.49976H9.83348Z"
            fill="white"
          />
        </svg> 
        <span class="footer__text">GIFT</span>
      </span>
      <span
        v-else-if="current === 'save'"
        key="save"
        class="footer__tag save"
        :class="{
          'no-padding': data !== 'SAVE'
        }"
      >
        <template v-if="data === 'SAVE'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M1.08385 3.82435L3.89191 6.6324L6.1298 4.39451L10.1931 8.45767"
              stroke="white"
              stroke-width="1.5"
            />
            <path
              d="M10.5219 4.59961L10.5219 9.00049L6.12103 9.00049"
              stroke="white"
              stroke-width="1.5"
            />
          </svg>
        </template>
        <span class="footer__text">{{ data }}</span>
      </span>
      <span
        v-else
        kye="default"
        class="footer__tag default"
      >
        {{ bagInfo.label }}
      </span>
    </transition>

    <em
      v-if="formatSum"
      class="cart-num"
    >
      {{ formatSum }}
    </em>

    <FooterCartTips ref="refFooterCartTips" />
    <FooterCartPopover 
      ref="refFooterCartPopover"
      :style-config="{
        x: GB_cssRight ? '32%' : '-32%',
      }"
    />
  </a>
</template>

<script>
import { mapState } from 'vuex'
import { daEventCenter } from '@/public/src/services/eventCenter'

import FooterCartTips from './tips.vue'
import FooterCartPopover from './popover/index.vue'
import { getNewPopOver } from './popover/utils'
import { sui_icon_tab_cart_30px,  sui_icon_tab_cart_selected_30px } from '@shein-aidc/icon-vue2'
import { web_cart_entry_click } from 'public/src/pages/cartNew/utils/metricTagsConfig.js'

const { GB_cssRight } = gbCommonInfo

export default {
  name: 'AppFooterCart',
  components: {
    FooterCartTips,
    FooterCartPopover,
    sui_icon_tab_cart_30px,
    sui_icon_tab_cart_selected_30px
  },
  props: {
    bagInfo: {
      required: true,
      type: Object,
    },
    footerData: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {
      targetElement: null,
      observer: null,
      current: 'default',
      data: null,
      transitionName: '',
      step2: null,
      timer: null,
      excludeScroll: false,

      GB_cssRight,
    }
  },
  computed: {
    ...mapState(['cartInfo']),
    IS_RW() {
      return this.footerData?.IS_RW
    },
    formatSum() {
      const { sum } = this.cartInfo || {}
      if (sum && sum > 0) {
        return sum > 99 ? '99+' : sum
      }
      return false
    }
  },
  mounted() {
    if (typeof window === 'undefined') return
    this.intersectionObserver()

    this.onExpose()
    appEventCenter.$on('pageOnload', () => this.onExpose())
  },
  beforeDestroy() {
    if (!this.observer || !this.targetElement) return
    this.observer.unobserve(this.targetElement)
  },
  methods: {
    /**
     * @typedef {Object} Step
     * @property {string} type default|free|gift|save
     * @property {boolean} noTips 是否显示 tips
     * @property {function} callback 动画执行结束的回调
     */
    setTag([step1, step2], config = {}) {
      this.excludeScroll = false
      this.clear()
      this.$nextTick(() => {
        if (step1) {
          this.excludeScroll = step1.excludeScroll || false
          if (step1.type === 'default') {
            this.callback = config.callback
            this.current = step1.type
            this.transitionName = ''
            // 没有动画 手动执行 afterEnter
            this.afterEnter()
          } else {
            if (step1.noTips || !step1.tips) {
              this.callback = config.callback
              // 没有动画 手动执行 afterEnter
              if (this.current === step1.type) this.afterEnter()
            } else {
              const { isNew, info } = getNewPopOver(step1)
              if (isNew) {
                const cfg = { ...config }
                if (step2) cfg.callback = null
                this.$refs.refFooterCartPopover.open(info, cfg)
              } else {
                const cfg = { ...config }
                if (step2) cfg.callback = null
                this.$refs.refFooterCartTips.open(step1, cfg)
              }
            }
            if (step1.tag) {
              this.current = step1.type
              this.data = step1.tag
              this.transitionName = 'footer-cart-fade'
            }
          }
        }
        if (step2) {
          this.step2 = step2
          this.timer = setTimeout(() => {
            this.callback = config.callback

            this.current = step2.type
            this.data = step2.tag
            this.step2 = null
            this.transitionName = 'footer-cart-zoom'
          }, 3000)
        }
      })
    },
    // 动画执行结束
    afterEnter() {
      if (typeof this.callback === 'function') {
        this.callback()
        this.callback = null
      }
    },
    // 打断动画
    clear() {
      if (this.excludeScroll) return
      this.transitionName = ''
      if (this.step2) {
        this.current = this.step2.type
        this.data = this.step2.tag
        this.step2 = null
        this.clearTimeout()
      }

      this.$refs.refFooterCartTips?.clear?.()
      this.$refs.refFooterCartPopover?.clear?.()

      // 动画被打断不会执行 afterEnter
      this.afterEnter()
    },
    clearTimeout() {
      clearTimeout(this.timer)
      this.timer = null
    },
    async getCurrentData() {
      const data = await window?._gb_cart_tag_tips_?.getCurrentTag()
      console.log('data', data)
      this.current = data?.type || 'default'
      this.data = data?.tag
    },
    intersectionObserver() {
      if (this.observer) return

      // 选择要观察的目标元素
      const targetElement = document.getElementsByClassName('j-index-footer')[0]
      if (!targetElement || typeof IntersectionObserver === 'undefined') return
      this.targetElement = targetElement

      // 观察器选项
      const options = {
        root: null, // 使用视口作为根元素
        rootMargin: '0px', // 根元素的边距
        threshold: 0.1 // 阈值，表示目标元素的可见比例
      }

      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.getCurrentData()
            window?._gb_cart_tag_tips_?.onceTip()
          }
        })
      }, options)

      this.observer.observe(targetElement)
    },
    setCurrentData(data) {
      this.current = data?.type || 'default'
      this.data = data?.tag
    },
    onClick() {
      web_cart_entry_click('2')
      window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
        daEventCenter.triggerNotice({
          daId: '2-15-1',
          extraData,
          bindData: {
            index: 4,
            tab_name: 'Bag',
          }
        })
      })
    },
    onExpose() {
      const footerDom = document.querySelector('.j-index-footer')
      if (!footerDom) return
      if (footerDom.style.display === 'none') return
      window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
        daEventCenter.triggerNotice({
          daId: '2-15-4',
          extraData,
          bindData: {
            index: 4,
            tab_name: 'Bag',
          }
        })
      })
    },
  }
}
</script>

<style lang="less">
.index-footer {
  a {
   position: relative;
   overflow: visible;
  }

  .footer__tag {
    position: absolute;
    display: inline-flex;
    align-items: center;
    padding: 0 2px;
    font-size: 12px;
    white-space: nowrap;
    border-radius: 8px;
    transform: translateX(-50%);

    &.default {
      padding: 0;
      white-space: initial;
    }

    &.free, &.save, &.gift {
      margin-top: 2px;
      height: 15px;
      color: #fff;
    }

    &.free {
      padding: 0 2px 0 4px;
      background: #198055;
    }

    &.gift {
      padding: 0 2px 0 4px;
    }

    &.gift, &.save {
      background: #fa6338;
    }

    &.no-padding {
      padding: 0;
    }

    .footer__text {
      margin: 0 -2px;
      transform: scale(0.75);
    }
  }

  @keyframes footer-cart-fade {  
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes footer-cart-zoom {  
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
      transform: translateX(-50%) scaleX(0.5);
    }
    100% {
      opacity: 1;
      transform: translateX(-50%) scaleX(1);
    }
  }

  .footer-cart-zoom-enter-active { /* stylelint-disable-line */
    animation: footer-cart-zoom 0.3s;
  }

  .footer-cart-zoom-leave-active { /* stylelint-disable-line */
    animation: footer-cart-zoom 0.3s reverse;
  }

  .footer-cart-fade-enter-active { /* stylelint-disable-line */
    animation: footer-cart-fade 0.3s;
  }

  .footer-cart-fade-leave-active { /* stylelint-disable-line */
    animation: footer-cart-fade 0.3s reverse;
  }
}
</style>
