import { getQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

export const BANNER_EXPORATION_TIME = 60 * 10 // 分钟

// bannerMap用于存储已经请求过的banner数据，每次请求时，先从map中取，如果没有再请求， 存入的key为pageType加上contentId
const bannerMap = new Map()

export async function bannerFetch({ pageType } = {}) {
  const previewPageType = getQueryString({ key: 'pageType' })
  let contentIds = ''
  if (previewPageType === pageType) {
    contentIds = getQueryString({ key: 'contentId' })
  }

  const cacheKey = `${pageType}_${contentIds}`

  // 如果map中有数据，直接返回
  if (bannerMap.has(cacheKey)) {
    return bannerMap.get(cacheKey)
  }

  const { info = {} } = await schttp({
    url: '/api/ccc/publicBanner/get',
    params: {
      contentIds,
      pageType,
    }
  }).catch(() => ({}))

  bannerMap.set(cacheKey, info)
  return info
}

export const getCookieKey = (key) => `${key}${gbCommonInfo.SiteUID}${gbCommonInfo.lang}`

export function removeApp(id, { removeHeight = true } = {}) {
  const element = document.getElementById(id)
  if (element) {
    element.parentNode.style.position = '' // 移除定位属性，防止影响页面布局

    if (removeHeight) {
      element.parentNode.style.height = '' // 移除高度。 切换页面不占位
    }
    
    element?.remove()
  }
}

/* 清空缓存 */ 
export const emitClearApp = ({ type }) => window.sheinBanner?.[type]?.Local.remove()

/**
 * @forced 强制更新
 * @return 返回值为存储数据是否成功
 * */ 
export const handleEmitApp = ({ type, el = {}, forced, cb } = {}) => {
  let outerHTML = el?.outerHTML || {}
  if (cb) {
    outerHTML = cb(outerHTML)
  }
  return window.sheinBanner?.[type]?.set(outerHTML, forced)
}
