import schttp from '@/public/src/services/schttp'

class Flash {
  constructor({ language }) {
    this.language = language
  }

  async getData({ cartBriefInfo } = {}) {
    const language = await this.language

    let tips = ''
    let cartItemList = []

    if (cartBriefInfo?.item_list?.length) {
      let flashMap
      try {
        flashMap = await this.getFlashMap(cartBriefInfo)
      } catch (e) { /* empty */ }
      const flashItemList = cartBriefInfo.item_list.filter(item => {
        return item.product_promotion_info?.find(f => {
          if (f.type_id === 10 && flashMap?.[f.promotion_id]?.flash_type === 1) return true
          if (f.type_id === 11) return true
          return false
        })
      })

      cartItemList = flashItemList

      const len = flashItemList.length
      if (len) {
        if (len > 1) {
          tips = language.SHEIN_KEY_PWA_31505.replace('{0}', len)
        } else {
          tips = language.SHEIN_KEY_PWA_31504
        }
      }
    }

    return {
      tag: false,
      tips,
      cartItemList,
    }
  }

  async getFlashMap(cartBriefInfo) {
    let flashMap = {}
    const promotionIds = []
    cartBriefInfo.item_list.forEach(item => {
      item.product_promotion_info?.forEach(promo => {
        if (promo.type_id === 10) promotionIds.push(promo.promotion_id)
      })
    })
    if (promotionIds.length) {
      flashMap = await this.getPromotionById(promotionIds)
    }
    return flashMap
  }

  async getPromotionById(promotion_id) {
    const res = await schttp({
      url: '/api/cart/postGetPromotionById/get',
      method: 'POST',
      data: {
        promotion_id,
      }
    })
    const list = res?.info?.list
    if (!list?.length) return {}
    return list.reduce((pre, cur) => {
      pre[cur.promotion_id] = cur
      return pre
    }, {})
  }
}

export default Flash
