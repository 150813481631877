import Cache from './cache'

class Gift {
  constructor({ language, key }) {
    this.language = language
    this.typeIds = [2, 4, 28] // 参与利诱点信息的活动类型
    this.cache = new Cache(key || 'cart_tag_tips__gift')
  }

  async getData(giftInfo, config = {}) {
    const {
      diffCache = true
    } = config

    const { promotion_range } = giftInfo || {}
    const target = promotion_range?.find?.(f => f.range > 0 && this.typeIds.includes(f.type_id))
    const status = !!target
    const lastStatus = diffCache && this.cache.data
    const language = await this.language

    this.cache.set(status)

    return {
      tag: status,
      // 如果上一次状态为 true，不再提示
      tips: lastStatus ? false : status && language.SHEIN_KEY_PWA_26511,
    }
  }
}

export default Gift
