class Lowstock {
  constructor({ language }) {
    this.language = language
  }

  async getData({ cartBriefInfo } = {}, apolloConfig) {
    const language = await this.language

    let tips = ''
    let cartItemList = []

    if (cartBriefInfo?.item_list?.length) {
      const lowstockItemList = cartBriefInfo.item_list.filter(item => {
        return item.real_time_inventory <= apolloConfig.stockLimit
      })

      cartItemList = lowstockItemList

      const len = lowstockItemList.length
      if (len) {
        if (len > 1) {
          tips = language.SHEIN_KEY_PWA_31501.replace('{0}', len)
        } else {
          tips = language.SHEIN_KEY_PWA_31500.replace('{0}', lowstockItemList[0].real_time_inventory)
        }
      }
    }

    return {
      tag: false,
      tips,
      cartItemList,
    }
  }
}

export default Lowstock
