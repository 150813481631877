export const POPOVER_TYPES = ['lowstock', 'lowerafteradd', 'flash', 'cartcoupon']
import { isObject } from '@shein/common-function'

export const getNewPopOver = (step) => {
  const isPopoverType = POPOVER_TYPES.includes(step.type)
  const tipsObj = isObject(step.tips)

  return {
    isNew: isPopoverType || tipsObj,
    info: isPopoverType ? step : step.tips,
  }
}
