class BestCoupon {
  constructor({ language }) {
    this.language = language
  }

  async getData({ cartBriefInfo } = {}) {
    const language = await this.language

    let tips = ''
    let cartCouponList = []

    const len = cartBriefInfo?.coupon_infos?.length
    if (len) {
      cartCouponList = cartBriefInfo.coupon_infos
      tips = language.SHEIN_KEY_PWA_31506
    }

    return {
      tag: false,
      tips,
      cartCouponList,
      cartCouponDiscountTotal: cartBriefInfo?.coupon_discount_total,
    }
  }
}

export default BestCoupon
