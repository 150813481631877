<template>
  <div
    v-show="show"
    :key="info.type"
    ref="cartPopover"
    class="footer-cart-popover"
    :style="style"
    @click.stop
  >
    <Icon
      class="close-ico"
      aria-hidden="true"
      size="12"
      name="sui_icon_close_18px"
      color="#C1C1C1"
      @click="clear"
    />
    <div
      class="tips"
      v-html="info.tips"
    >
    </div>
    <div 
      class="content" 
      @click="onClick"
    >
      <div class="left">
        <PopoverCoupon 
          v-if="isCoupon"
          :discountText="info.cartCouponDiscountTotal &&info.cartCouponDiscountTotal.amountWithSymbol"
          :language="language"
        />
        <PopoverGoods
          v-else
          :list="info.cartItemList"
        />
      </div>
      <s-button
        :type="['H56PX', 'primary']"
        class="go-cart"
      >
        Go To Cart
      </s-button>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import PopoverGoods from './goods.vue'
import PopoverCoupon from './coupon.vue'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from '@/public/src/services/eventCenter'
import { Button as SButton } from '@shein/sui-mobile'
daEventCenter.addSubscriber({ modulecode: '2-15-1' })

const typeMap = {
  'lowstock': 'oos',
  'lowerafteradd': 'mark_down',
  'flash': 'flash_sale',
  'cartcoupon': 'coupon',
}
const defaultStyle = {
  x: '-32%',
  y: '-100%',
  triangleLeft: '50%',
}

export default {
  components: {
    Icon,
    PopoverGoods,
    PopoverCoupon,
    SButton,
  },
  props: {
    styleConfig: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      language: {},
      info: {},
      show: false,
      callback: null,

      timer: null,
    }
  },
  computed: {
    style() {
      const obj = {
        ...defaultStyle,
        ...this.styleConfig,
      }
      return {
        '--box-transform': `translateX(${obj.x}) translateY(${obj.y})`,
        '--triangle-left': obj.triangleLeft,
      }
    },
    isCoupon() {
      return this.info.type == 'cartcoupon'
    },
  },
  mounted() {
    this.getLanguage()
  },
  methods: {
    getLanguage() {
      schttp({
        url: '/api/common/language/get',
        params: { page: 'cart_tag_tips' }
      }).then((res) => {
        this.language = res?.language || {}
      })
    },
    open(data, config = {}) {
      this.callback = config.callback

      this.info = data || {}
      this.show = true
      window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
        daEventCenter.triggerNotice({
          daId: '2-15-2',
          extraData,
        })
      })
      
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.clear()
        }, 3000)
      }
    },
    clearTimer() {
      if (!this.timer) return
      clearTimeout(this.timer)
      this.timer = null
    },
    // 打断动画
    clear() {
      this.clearTimer()
      this.show = false
      if (typeof this.callback === 'function') {
        this.callback()
        this.callback = null
      }
    },
    onClick() {
      window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
        daEventCenter.triggerNotice({
          daId: '2-15-3',
          extraData,
        })
      })

      const { type, cartItemList } = this.info
      const toCartData = {
        type: typeMap[type],
        products: (cartItemList || []).map(v => v.id)
      }
      if (!toCartData.type) return
      // 跳转滚动到购物车商品行且高亮
      if (toCartData.products.length === 1) {
        window._gb_app_.$router.push({
          name: 'cart',
          params: {
            cartId: toCartData.products[0]
          }
        })
        return
      }
      window._gb_app_.$router.push({
        name: 'cart',
        params: toCartData
      })
    }
  }
}
</script>

<style lang="less" scoped>
.footer-cart-popover {
  position: absolute;
  top: -10px;
  transform: var(--box-transform);
  padding: 16/75rem;
  width: 406/75rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: left;
  &::before {
    content: '';
    position: absolute;
    left: var(--triangle-left);
    margin-left: -2/37.5rem;
    border-top: 4/37.5rem solid transparent;
    border-bottom: 4/37.5rem solid rgba(0, 0, 0, 0.8);
    border-right: 4/37.5rem solid rgba(0, 0, 0, 0.8);
    border-left: 4/37.5rem solid transparent;
    transform: rotate(45deg);
    bottom: -3/37.5rem;
  }

  .tips {
    padding-right: 8/37.5rem;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .content {
    margin-top: 8/37.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      flex: 1;
      margin-right: 16/37.5rem;
    }
    .go-cart {
      background: #FA6338;
      font-size: 12px;
      border-radius: 24px;
      width: 84/37.5rem;
      padding: 0 4/37.5rem;
      font-weight: normal;
    }
  }
  .close-ico {
    position: absolute;
    right: 3px;
    top: 3px;
  }
}
</style>
