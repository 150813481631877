class Flash {
  constructor({ language }) {
    this.language = language
  }

  async getData({ cartBriefInfo } = {}) {
    const language = await this.language

    let tips = ''
    let cartItemList = []

    if (cartBriefInfo?.item_list?.length) {
      const reductedList = cartBriefInfo.item_list.filter(item => {
        return item.is_reduce === 1
      })

      cartItemList = reductedList

      const len = reductedList.length
      if (len) {
        if (len > 1) {
          tips = language.SHEIN_KEY_PWA_31503.replace('{0}', len)
        } else {
          tips = language.SHEIN_KEY_PWA_31502
        }
      }
    }

    return {
      tag: false,
      tips,
      cartItemList,
    }
  }
}

export default Flash
