import { reactive, ref } from 'vue'

// 全局状态对象
const state = reactive({
  botInitMessage: null,
  abtInfo: null
})

export default () => {
  // 设置数据
  const setData = (key, value) => {
    state[key] = value
  }

  // 获取数据
  const getData = (key) => {
    return ref(state[key])
  }

  // 清除数据
  const clearData = (key) => {
    if (key in state) {
      delete state[key]
    }
  }

  // 清除所有的数据
  const clearAllData = () => {
    Object.keys(state).forEach(key => {
      delete state[key]
    })
  }
    
  return {
    setData,
    getData,
    clearData,
    clearAllData
  }
}
