

import schttp from 'public/src/services/schttp'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
function isOpenPrerenderStoreApp() {
  const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  return !isIos // ios不预取html
}
class StorePageDataManager {
  constructor() {
    // 单例模式
    if (StorePageDataManager.instance) {
      return StorePageDataManager.instance
    }
    this.init()
    StorePageDataManager.instance = this

  }
  init() {
    this.fullPath = ''
    this.schttpAbortCon = null
    this.pageDataRequest = null
    this.pageSsrDataResolved = false
  }

  getEnabelPrerenderByAbt() {
    return new Promise(async (resolve) => {
      if (typeof window === 'undefined') {
        resolve();
        return;
      }
      try {
        const { StorePerformanceSpaHybration } = await getUserAbtData()
        resolve(StorePerformanceSpaHybration?.p?.StorePerformanceSpaHybration === 'new')
      } catch (e) {
        console.error(e)
        resolve();
      }
    })
  }
  async prerenderRequest(route) {
    if (typeof window === 'undefined') return this.request(route);
    if (!isOpenPrerenderStoreApp() || window.__store_init_container) return this.request(route);
    if (window.__store_promise_preload_html) return window.__store_promise_preload_html;
    if (gbCommonInfo?.STORE_PRERENDER_SWITCH === 'on') {
      let __resolve;
      window.__store_promise_preload_html = new Promise((resolve) => {
        __resolve = resolve
      })
      let dom = document.querySelector('#prerender-loading')
      try {
        const enabelPrerender = await this.getEnabelPrerenderByAbt();
        if (!enabelPrerender) {
          dom.style.display = 'none'
          __resolve()
          return;
        }
        window._gb_app_.$store.state.rootStatus.exchangingSpaScene = true;
        [document.querySelector('#header-app'), document.querySelector('#top-banner')].forEach(ele => {
          ele && (ele.style.display = 'none')
        })
       
        dom.style.display = 'block'
        const query = { ...route.query }
        query.preloadHtmlUrl = route.fullPath
        return this.request({
          ...route,
          query
        }).then((res) => {
          return new Promise((resolve) => {
            const { contextForSSR, ssrHTML, ssrVueCSS, ssrSuiCSS, preloadImgs = [] } = res || {}
            if (!ssrHTML || !ssrSuiCSS) {
              resolve()
              return
            }
            dom.style.display = 'none'
            if (ssrSuiCSS) {
              document.querySelector('#ssrSuiCSS').textContent += ssrSuiCSS
            }
            document.querySelector('#prerender-app').appendChild(document.createRange().createContextualFragment(ssrHTML).querySelector('#store_container'))
            document.querySelector('#prerender-css-store').innerHTML = ssrVueCSS
            document.querySelector('#prerender-app #store_container').setAttribute('data-server-rendered', true)
            window.__store_prerender_el = document.querySelector('#prerender-app #store_container')
            // header头部可能会在吸顶状态, 进行复位
            window.forceUpdateHeaderFixed?.({ forceFixed: false, routeName: 'page_store' })
            let goodsImgs
            if (preloadImgs.length) goodsImgs = preloadImg(preloadImgs)
            requestAnimationFrame(() => { // 上屏前
              window.scrollTo(0, 0);
              window.storeSSRData = contextForSSR
              goodsImgs
                ? goodsImgs.then(() => requestAnimationFrame(() => resolve()))
                : requestAnimationFrame(() => resolve())
            })
          })
        }).finally(() => {
          if (dom) {
            dom.style.display = 'none'
          }
          __resolve()
        })
      } catch (e) {
        console.error(e)
        __resolve()
        return this.request(route)
      }
    }
    return this.request(route)
  }
  request(route) {
    if (!route) return null
    if (route.fullPath !== this.fullPath) {
      this.fullPath = route.fullPath
      const query = route.query;
      this.pageDataRequest = this.fetchPageData(route.query)
    }
    return this.pageDataRequest
  }
  async fetchPageData(query = {}) {
    if (this.schttpAbortCon) {
      this.schttpAbortCon.abort()
    }
    this.schttpAbortCon = new SchttpAbortCon()
    const params = {
      ...query,
      i18n: true,
    }
    try {
      const resData = await schttp({
        url: '/api/store/page/get',
        params,
        signal: this.schttpAbortCon.signal,
      })
      return resData
    } catch (error) {
      console.log("========error", error)
      this.fullPath = ''
      return {} // todo 错误页面
    }
  }

  reset() {
    this.init()
  }

}

export default new StorePageDataManager()

