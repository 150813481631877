<template>
  <div 
    class="popover-coupon"
  >
    <div class="top">
      <SAdapterText
        min-size="11"
        :text="discountText"
      />
    </div>
    <div class="bottom">
      {{ language.SHEIN_KEY_PWA_31507 }}
    </div>
  </div>
</template>

<script>
import { AdapterText as SAdapterText } from '@shein/sui-mobile'

export default {
  name: 'FooterPopoverCoupon',
  components: {
    SAdapterText,
  },
  props: {
    discountText: {
      type: String,
      default: '',
    },
    language: {
      type: Object,
      default: () => ({})
    },
  },
}
</script>

<style lang="less" scoped>
.popover-coupon {
  width: 60/37.5rem;
  height: 40/37.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-image: url(https://img.ltwebstatic.com/images3_acp/2024/05/29/4e/17169741368156f49839a54ed3b121e95ce263b150.png);
  .top {
    color: #FA6338;
    font-size: 12px;
    font-weight: 860;
    line-height: 14px;
    overflow: hidden;
    width: 88%;
    text-align: center;
    text-overflow: ellipsis;
  }
  .bottom {
    margin: 0 auto;
    max-width: 56/37.5rem;
    line-height: 8px;
    color: #FF6438;
    font-weight: 700;
    margin-top: 4/37.5rem;
    font-size: 8px;
  }
}
</style>
