export const createUserOrderRoute = (langPath = '') => {
  return [
    {
      path: `${langPath}/user/orders/detail/:billno`,
      name: 'UserOrdersDetail',
      meta: {
        keepAlive: false,
        pageType: 'ordersDetail',
        monitorTag: 'orders-detail',
        isCssInVue: true,
        notNeedCommonHeader: true
      },
      component: () =>
        import(
          /* webpackChunkName: "orders_detail" */ '../../user/child_pages/orders/detail/container.vue'
        )
    },
    {
      path: `${langPath}/user/orders/virtualDetail/:billno`,
      name: 'UserOrdersVirtualDetail',
      meta: {
        keepAlive: false,
        pageType: 'virtualOrdersDetail',
        monitorTag: 'virtual-orders-detail',
        isCssInVue: true,
        notNeedCommonHeader: true
      },
      component: () =>
        import(
          /* webpackChunkName: "virtual_orders_detail" */ '../../user/child_pages/orders/virtualDetail/container.vue'
        )
    },
    {
      path: `${langPath}/user/orders/list`,
      name: 'UserOrdersList',
      component: () => import(/* webpackChunkName: "orders_list" */ 'public/src/pages/user/child_pages/orders/list/container.vue'),
      meta: {
        keepAlive: true,
        pageType: 'userPage',
        monitorTag: 'order-list',
        isCssInVue: true,
        notNeedCommonHeader: true,
        needLogin: true,
      }
    },
  ]
}
