import schttp from 'public/src/services/schttp'
import TMG from '@shein/time-management-guru'
import { isLogin } from 'public/src/pages/common/utils/index.js' 

// 获取token数据
export const getImTokenMasterData = async (params) => {
  return await TMG.useQuickRequest(
    `robot/getImToken`,
    params,
    { onlyFirstUseQuick: true }
  )
}

// 获取language数据
export const getRobotLanguageData = async (params = {}) => {
  return await TMG.useQuickRequest(
    `robot/getRobotLanguage`,
    params,
    { onlyFirstUseQuick: true }
  )
}

export const TMGTriggerForImToken = () => {
  TMG.triggerQuickRequests('robot', {
    quickRequestNames: ['getImToken', 'getRobotLanguage'],
    throttle: true
  })
}

// 注册机器人预请求
export const robotRequests = {
  quickRequests: {
    getImToken: {
      params: () => {
        return { email: '' }
      },
      request: async () => {
        // 未登录不需要获取token
        if (!isLogin()) return {}

        return await schttp({
          url: '/api/robot/cschat/token/get',
          method: 'POST',
          params: {
            email: ''
          }
        }).catch(e => {
          console.error(e)
        })
      },
      // 设置会话缓存过期时间
      sessionExpired: 1000 * 60 * 3
    },
    getRobotLanguage: {
      params: () => {
        return { page: 'pre_online_help' }
      },
      request: async () => {
        return await schttp({
          url: '/api/common/language/get',
          params: {
            page: 'pre_online_help'
          }
        }).catch(e => {
          console.error(e)
        })
      },
      // 设置会话缓存过期时间
      sessionExpired: 1000 * 60 * 3
    },
  }
}
