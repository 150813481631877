var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"index-footer mshe-z-header j-index-footer",style:({
    display: ['index', 'category', 'tabbarNew', 'user'].includes(_vm.tabActive) ? 'show' : 'none'
  }),attrs:{"data-aaa":_vm.tabActive || ''}},[(_vm.footerData)?[_c('a',{directives:[{name:"tap",rawName:"v-tap",value:({
        id: '2-15-1',
        data: {
          index: 1,
          tab_name: 'Shop'
        },
      }),expression:"{\n        id: '2-15-1',\n        data: {\n          index: 1,\n          tab_name: 'Shop'\n        },\n      }"}],staticClass:"ft-li j-index-tab-list j-index-tab-list-home waves-btn waves-btn-auto waves-effect waves-classic",class:_vm.homeInfo.class,attrs:{"data-href":_vm.homeInfo.href,"tabindex":"0","role":"link","aria-label":_vm.homeInfo.label,"data-title":"home","DA-type":"syncClick","DA-category":"导航栏","DA-action":"ClickShop"}},[(_vm.homeInfo.defaultImgSrc && _vm.homeInfo.selectedImgSrc)?[_c('div',{staticClass:"index-footer__ccc-skin-warp"},[_c('div',{staticClass:"index-footer__ccc-skin-icon",style:({
              backgroundImage: `url(${_vm.homeInfo.defaultImgSrc})`
            }),attrs:{"tabindex":"-1","aria-hidden":"true","role":"none"}}),_vm._v(" "),_c('div',{staticClass:"index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active",style:({
              backgroundImage: `url(${_vm.homeInfo.selectedImgSrc})`
            }),attrs:{"tabindex":"-1","aria-hidden":"true","role":"none"}})])]:[_c('i',{staticClass:"sui_icon_tab_shop_30px tabbar-icon",class:_vm.homeInfo.iconClass}),_vm._v(" "),_c('i',{staticClass:"sui_icon_tab_shop_selected_30px tabbar-icon_active",class:_vm.homeInfo.iconClass})],_vm._v("\n      "+_vm._s(_vm.homeInfo.label)+"\n    ")],2),_vm._v(" "),_c('a',{directives:[{name:"tap",rawName:"v-tap",value:({
        id: '2-15-1',
        data: {
          index: 2,
          tab_name: 'Category'
        },
      }),expression:"{\n        id: '2-15-1',\n        data: {\n          index: 2,\n          tab_name: 'Category'\n        },\n      }"}],staticClass:"ft-li j-index-tab-list j-index-tab-list-category waves-btn waves-btn-auto waves-effect waves-classic",class:_vm.categoryInfo.class,attrs:{"data-href":_vm.categoryInfo.href,"data-title":"category","DA-type":"syncClick","DA-category":"导航栏","DA-action":"ClickCategory","tabindex":"0","role":"link","aria-label":_vm.categoryInfo.label}},[(_vm.categoryInfo.defaultImgSrc && _vm.categoryInfo.selectedImgSrc)?[_c('div',{staticClass:"index-footer__ccc-skin-warp"},[_c('div',{staticClass:"index-footer__ccc-skin-icon",style:({
              backgroundImage: `url(${_vm.categoryInfo.defaultImgSrc})`
            }),attrs:{"tabindex":"-1","aria-hidden":"true","role":"none"}}),_vm._v(" "),_c('div',{staticClass:"index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active",style:({
              backgroundImage: `url(${_vm.categoryInfo.selectedImgSrc})`
            }),attrs:{"tabindex":"-1","aria-hidden":"true","role":"none"}})])]:[_c('i',{staticClass:"sui_icon_tab_category_30px tabbar-icon",class:_vm.categoryInfo.iconClass}),_vm._v(" "),_c('i',{staticClass:"sui_icon_tab_category_selected_30px tabbar-icon_active",class:_vm.categoryInfo.iconClass})],_vm._v("\n      "+_vm._s(_vm.categoryInfo.label)+"\n    ")],2),_vm._v(" "),_c('a',{directives:[{name:"tap",rawName:"v-tap",value:({
        id: '2-15-1',
        data: {
          index: 3,
          tab_name: 'New'
        },
      }),expression:"{\n        id: '2-15-1',\n        data: {\n          index: 3,\n          tab_name: 'New'\n        },\n      }"}],staticClass:"ft-li j-index-tab-list j-index-tab-list-activity waves-btn waves-btn-auto waves-effect waves-classic",class:_vm.newInfo.class,attrs:{"data-href":_vm.newInfo.href,"data-title":"new","DA-type":"syncClick","DA-category":"导航栏","DA-action":"ClickNew","tabindex":"0","role":"link","aria-label":_vm.newInfo.label}},[(_vm.newInfo.defaultImgSrc && _vm.newInfo.selectedImgSrc)?[_c('div',{staticClass:"index-footer__ccc-skin-warp"},[_c('div',{staticClass:"index-footer__ccc-skin-icon",style:({
              backgroundImage: `url(${_vm.newInfo.defaultImgSrc})`
            }),attrs:{"tabindex":"-1","aria-hidden":"true","role":"none"}}),_vm._v(" "),_c('div',{staticClass:"index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active",style:({
              backgroundImage: `url(${_vm.newInfo.selectedImgSrc})`
            }),attrs:{"tabindex":"-1","aria-hidden":"true","role":"none"}})])]:[_c('i',{staticClass:"sui_icon_tab_new_30px tabbar-icon",class:_vm.newInfo.iconClass}),_vm._v(" "),_c('i',{staticClass:"sui_icon_tab_new_selected_30px tabbar-icon_active",class:_vm.newInfo.iconClass})],_vm._v("\n      "+_vm._s(_vm.newInfo.label)+"\n    ")],2),_vm._v(" "),_c('Cart',{ref:"refCart",attrs:{"bag-info":_vm.bagInfo,"footer-data":_vm.footerData}}),_vm._v(" "),_c('a',{directives:[{name:"tap",rawName:"v-tap",value:({
        id: '2-15-1',
        data: {
          index: 5,
          tab_name: 'Me'
        },
      }),expression:"{\n        id: '2-15-1',\n        data: {\n          index: 5,\n          tab_name: 'Me'\n        },\n      }"}],staticClass:"ft-li j-index-tab-list j-index-tab-list-user waves-btn waves-btn-auto waves-effect waves-classic",class:_vm.meInfo.class,attrs:{"data-href":_vm.meInfo.href,"DA-type":"syncClick","DA-category":"导航栏","DA-action":"ClickMe","tabindex":"0","role":"link","aria-label":_vm.meInfo.label},on:{"click":function($event){return _vm.markPoint('toNextPageClick', 'public')}}},[(_vm.meInfo.defaultImgSrc && _vm.meInfo.selectedImgSrc)?[_c('div',{staticClass:"index-footer__ccc-skin-warp"},[_c('div',{staticClass:"index-footer__ccc-skin-icon",style:({
              backgroundImage: `url(${_vm.meInfo.defaultImgSrc})`
            }),attrs:{"tabindex":"-1","aria-hidden":"true","role":"none"}}),_vm._v(" "),_c('div',{staticClass:"index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active",style:({
              backgroundImage: `url(${_vm.meInfo.selectedImgSrc})`
            }),attrs:{"tabindex":"-1","aria-hidden":"true","role":"none"}})])]:[_c('i',{staticClass:"sui_icon_tab_me_30px tabbar-icon",class:_vm.meInfo.iconClass}),_vm._v(" "),_c('i',{staticClass:"sui_icon_tab_me_selected_30px tabbar-icon_active",class:_vm.meInfo.iconClass})],_vm._v("\n      "+_vm._s(_vm.meInfo.label)+"\n    ")],2),_vm._v(" "),_c('div',{staticClass:"j-sidebar-icon-mask"})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }