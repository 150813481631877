<template>
  <div 
    v-show="!rootStatus.exchangingSpaScene"
    id="app"
  >
    <keep-alive>
      <router-view
        v-if="$route.meta && $route.meta.keepAlive"
        :key="$route.name"
      />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <client-only>
      <sidecategory
        v-if="showCategory"
        ref="sidecategory"
        render-type="sidecat"
      />
      <similar-product-vue v-if="rootStatus.importSimilarVue" />

      <lazy-mount v-if="rootStatus" mount-prop-name="show">
        <s-loading
          class="root-loading" 
          :show="rootStatus.loading"
          type="curpage" 
        />
      </lazy-mount>
      
      <!--
        可用以下 root mutations 变更状态, 参数格式同组件
        containerCoverStyle 和 curPageMaskTopBoundSelector 在loading关闭后自动恢复默认
        this.changeRootSLoadingStatus({
          show: false,
          containerCoverStyle: {}, // 可选
          curPageMaskTopBoundSelector: '' // 可选
          type: 'curpage',
          curPageScrollFix: true, // 滚动条是否可滚动 默认禁止滚动
        })
      -->

      <lazy-mount v-if="rootStatus" mount-prop-name="show">
        <s-loading
          :show="rootSLoading.show"
          :container-cover-style.sync="rootSLoading.containerCoverStyle"
          :cur-page-mask-top-bound-selector.sync="rootSLoading.curPageMaskTopBoundSelector"
          :type="rootSLoading.type"
          :cur-page-scroll-fix="rootSLoading.curPageScrollFix"
          :mask-transparent="rootSLoading.maskTransparent"
        />
      </lazy-mount>
    </client-only>
    <AppFooter />
  </div>
</template>
<script>
import ClientOnly from 'vue-client-only'
import { mapState, mapActions, mapMutations } from 'vuex'
import { install } from 'public/src/pages/common/similar_product_modal/index.js'
import { useLoading } from 'public/src/pages/common/requestLoading.js'
import { installAppHeader, installAppTopBanner, installAppPolicyBanner, installAppBranch } from 'public/src/pages/common/banner/exampleBanner.js'
import ControlHeader from 'public/src/pages/common/banner/controlHeader.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import LazyMount from 'public/src/pages/components/ccc/base/LazyMount.js'
import AppFooter from './footer/index.vue'
import SILog from 'public/src/pages/common/monitor/index'

export default {
  name: 'ProductApp',
  components: {
    ClientOnly,
    LazyMount,
    AppFooter,
    similarProductVue: () => import(/* webpackChunkName: "similar-product" */ './../common/similar_product_modal/similar_product_modal.vue'),
    sidecategory: () => import( /* webpackChunkName: "sidecategory" */ '../category/sidecontainer.vue'),
  },
  data () {
    return {
      history: typeof history !== 'undefined' ? history : undefined,
      showCategory: false,
      prefetchCartSource: false
    }
  },
  computed: {
    ...mapState(['rootStatus', 'rootSLoading']),
    // showBanner() {
    //   return this.$route.name 
    //     ? this.$route.name != 'config_index' 
    //     : (typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.contextForSSR?.ssrPageType !== 'config_index')
    // }
  },
  watch: {
    $route (to, from) {
      this.$nextTick(() => {
        if (from.query.from === 'popupScanResult' && window.appRouteExtData.direction === -1) {
          appEventCenter.$emit('openSearchAndPopupScanResult')
        }
        // if (from.query.from === 'popupScanResult' && window.appRouteExtData.direction !== -1 && !from.meta?.cameraFrom) {
        //   appEventCenter.$emit('hiddenMiddlePage')
        // }

        if (from.query.ici && from.query.ici.indexOf('CameraSearch') > -1 && window.appRouteExtData.direction === -1) {
          appEventCenter.$emit('openMiddlePage')
        }
        if (from.query.ici && from.query.ici.indexOf('CameraSearch') > -1 && window.appRouteExtData.direction !== -1) {
          appEventCenter.$emit('hiddenMiddlePage')
        }
        this.initRedpointCheck(to.name)
      })
    }
  },
  async mounted () {
    const self = this
    window.fspSDK.updateOptions({
      reporterHandler({ name, value, payload = {} } = {}) {
        if (!name || !value || window.isClientBot) {
          return
        }

        const resource = payload.resource || ''
        if (self.$route?.name === 'campaigns' && value > 3000) {
          try {
            const fileds = SILog.getFields()
            SILog.SIMetric.metricCount({
              metric_name: 'activity_long_fsp_total',
              tags: {
                site: window?.gbCommonInfo?.SiteUID || '',
                identity: self.$route?.params?.identity || '',
                pathname: location.pathname,
                os_name: fileds?.os_name || '',
                device_model: fileds?.device_model || '',
                fspValue: value,
              },
            }, {
              immediate: true,
            })
            // window.ErrorJs.sendError('error', `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`, {
            //   appName: 'shein_PWA_new',
            //   errorMark: 'Activity_Long_FSP',
            //   error_type: 'script_error',
            //   errorInfo: {
            //     identity: self.$route?.params?.identity,
            //     fspValue: value,
            //     pathname: self.$route?.path,
            //     href: location.href,
            //   },
            //   reportList: [
            //     {
            //       metricName: 'activity_long_fsp_total',
            //       site: gbCommonInfo?.SiteUID,
            //       identity: self.$route?.params?.identity,
            //       pathname: self.$route?.path,
            //     },
            //   ]
            // })
          } catch (error) {
            console.log('error', error)
          }
        }
        window.TPM?.run({
          marketing: 'ClientAnalytics',
          method: '_defineTrack',
          params: {
            data: {
              resource,
              data: [
                {
                  key_path: name,
                  values: { num: value }
                },
              ]
            },
            options: {
              random: 1,
              immediate: true,
            },
          }
        })
      }
    })

    window.fspSDK.observeRouteChange(this.$router)
    this.installHeaderDom()

    if (this.$route && this.$route.meta && this.$route.meta.monitorTag && typeof window !== 'undefined') {
      window.pageTypeForMonitor = this.$route.meta.monitorTag
    }
    // 【not delete】fps automated testing
    window.appVueInstance = this

    this.initRedpointCheck(this.$route.name)

    install(() => {
      this.changeRootStatus({
        importSimilarVue: true
      })
    })
    this.prefetchResources()
  },

  created() {
    useLoading(({ show, type, containerCoverStyle = {}, curPageScrollFix = true, maskTransparent = false }) => {
      this.changeRootSLoadingStatus({ show, type, containerCoverStyle, curPageScrollFix, maskTransparent })
    })
  },

  methods: {
    ...mapActions(['fetchIndividuation', 'checkRedpoint']),
    ...mapMutations(['changeRootStatus', 'changeRootSLoadingStatus']),

    installHeaderDom() {
      // 只控制页面的展示，不控制页面的逻辑
      const controlHeader = new ControlHeader({
        components: [  // 顺序和渲染的顺序保持一致。
          {
            displayDom: '#branch-app', // 切换展示dom
            install: async () => {
              return installAppBranch({ router: this.$router, store: this.$store, app: this })
            },
            onCheck() {
              return true // 组件内部自己控制
            }
          },
          {
            displayDom: '#top-banner',
            install: () => {
              return installAppTopBanner({ el: '#top_banner_app', router: this.$router, store: this.$store })
            },
            onCheck(route) {
              return ['index', 'productDetail', 'category', 'productList', 'page_store', 'activity', 'flash_sale', 'PTRecProductList', 'PTFeedbackRecList', 'campaigns'].includes(route.meta.pageType) || ['wishlistGroupShare'].includes(route.name)
            },
          },
          { 
            displayDom: '#header-app',
            install: () => {
              return installAppHeader({ el: '#header-page', router: this.$router, store: this.$store, showcategory: () => {
                this.showcategoryHandle()
              } })
            },
            onCheck(route) {
              return !route?.meta?.notNeedCommonHeader
            }
          },
          {
            displayDom: '#shein-policy-app',
            install: () => {
              return installAppPolicyBanner({ el: '#j-policy-banner', router: this.$router, store: this.$store })
            },
            onCheck(route) {
              return ['productDetail', 'category', 'productList', 'page_store', 'activity', 'flash_sale', 'PTRecProductList', 'PTFeedbackRecList'].includes(route.meta.pageType) || ['wishlistGroupShare'].includes(route.name)
            },
          },
        ]
      }) 
      controlHeader.init(this.$route)
      const unwatch = this.$watch('$route', (to) => {
        controlHeader.update(to)
      })
      // 在销毁组件前调用取消监听函数，防止内存泄漏
      this.$once('hook:beforeDestroy', unwatch)
    },
    
    /**
     * 初始化红点检测逻辑
     * @description 白名单内的页面不执行红点检测，检测完触发 repointCheckDone 事件
     */
    initRedpointCheck (routeName) {
      const notNeedRouteNames = ['pre_search']
      if (notNeedRouteNames.includes(routeName)) return
      requestIdleCallback(() => {
        this.checkRedpoint(() => {
          appEventCenter.$emit('redpointCheckDone')
        })
      })
    },

    showcategoryHandle() {
      // first load sidecate code, seconde show sidecate
      !this.showCategory ? this.showCategory = true : this.$refs?.sidecategory?.clickSideIconEvent()
    },
    prefetchResources() {
      // el 必须是渲染完的
      if(this.$route.name == 'cart' || this.prefetchCartSource) return
      this.prefetchCartSource = true
      requestIdleCallback(() => {
        prefetchResource.listen({
          el: this.$el,
          prefetchList: [
            {
              chunkName: 'cart_index_new',
              relType: 'prefetch'
            },
            {
              chunkName: 'cart_header_checkout',
              relType: 'prefetch'
            },
            {
              chunkName: 'cart_bottom_content',
              relType: 'prefetch'
            },
            {
              chunkName: 'pre_search',
              relType: 'prefetch'
            },
          ],
          delay: 2000, // 默认两秒
          // prefetchCallback: ({ status, info }) => {
          //   console.log(`%cprefetchCallback %c${status}`, 'color: red;', 'color: green;', info)
          // },
        })
      })
    },
  }
}
</script>

<style lang="less">
// 详情页找相似 or 快速加车找相似上面的 售罄文案
.soldout-warning {
  padding: .213333rem .32rem;
  line-height: 1.2;
  background-color: #FFF1D5;
  .font-dpr(24px);
  color: #222;
  text-align: center;
  /* rw:begin */
  background-color: @sui_color_black;
  color: @sui_color_white;
  // 详情页
  &.goods-detial {
    position: absolute;
    width: 100%;
    top: 0;
    transform: translateY(-100%);
    /* rtl:begin:ignore */
    left: 0;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .28s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

// 触觉反馈优化 当前只针对shein
[brd=sh] .haptics-feedback:active {
  background: #F6F6F6;
}
</style>
