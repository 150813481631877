//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
const {langPath, SiteUID, SWITCH_GIFTCARD_VERSION } = gbCommonInfo

/**
 *showNewVersion(礼品卡新旧版本代码切换有三种情况)
    1.全开
    阿波罗的值为'OPENALL',数据类型String
  2.开启部份站点
    阿波罗的值为['ca','us','www'],数据类型Array<String>
  3.全关
    阿波罗的值不等于'OPENALL'，又不是个数组
  */
let showNewVersion

if (Array.isArray(SWITCH_GIFTCARD_VERSION)) {
  showNewVersion = SWITCH_GIFTCARD_VERSION.includes(SiteUID)
} else {
  showNewVersion = SWITCH_GIFTCARD_VERSION === 'OPENALL'
}

const giftcardRoutes = [
  {
    path: '/giftcard.html',
    name: 'giftcard',
    meta: {
      code: 'giftcard',
      keepAlive: true,
      pageType: 'giftcard',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => {
      return showNewVersion ?
        import(/* webpackChunkName: "giftcard-home" */ '../../giftcard/child_pages/home.vue') :
        import(/* webpackChunkName: "giftcard-home" */ '../../giftcard/child_pages/home_old.vue')
    },
  },
  {
    path: '/giftcard/checkout/first/:id',
    name: 'checkout',
    meta: {
      code: 'checkout',
      keepAlive: true,
      pageType: 'giftcard',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "giftcard-checkout" */ '../../giftcard/child_pages/checkout.vue')
  },
  {
    path: '/giftcard/checkout/again/:id',
    name: 'checkout_again',
    meta: {
      code: 'checkout_again',
      keepAlive: false,
      pageType: 'giftcard',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "giftcard-checkout-again" */ '../../giftcard/child_pages/checkout_again.vue')
  }
]

// 站点设置
giftcardRoutes.forEach((router) => {
  router.path = `${langPath}${router.path}`
})

export {
  giftcardRoutes
}
