export const createUserNewsRoute = (langPath = '') => {
  return {
    path: `${langPath}/user/news`,
    name: 'user_news',
    meta: {
      keepAlive: true,
      pageType: 'user_news',
      monitorTag: 'user_news',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import( /* webpackChunkName: "user_news" */ '../../user_news/index.vue'),
  }
}
