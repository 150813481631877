/**
 *
 * 只在客户端渲染的路由
 */
import { Toast } from '@shein/sui-mobile'
import { homeRouter } from './home-router'
import { createSecondaryHomeRoute } from './secondary-home'
import { detailRouters } from './goods-detail-router'
import {
  createListRoute,
  createUnderPricesListRoute,
  createPickedInfoListRoute,
  createPicksRoute,
  createDiscountChannelListRoute
} from './list-router'
import { createStoreRoute } from './store-router'
import { createAdvertisingRoute } from './advertising'
import { preSearchRoutes } from './presearch-router'
import { freeTrialRoutes } from './free-trial-router'
import { giftcardRoutes } from './giftcard-router'
import { checkoutRoutes } from './checkout-router'
import { cartRoutes } from './cart-router'
import { userRoutes } from './user-router'
import { ordersRoutes } from './orders-router'
// import { buyapowaRoutes } from './buyapowa-router'
import { createArticleRoute } from './article-router'
import { lookbookRoutes } from './lookbook-router'
import { liveRoutes } from './live-router'
import { robotRoutes } from './robot-router'
import { faqRoutes } from './faq-router'
import { sheinxRoutes } from './sheinx-router'
import { shareEarnRoutes } from './shareandearn-router'
import couponProductRoutes from './coupon-product'
import { abtservice } from 'public/src/services/abt'
import schttp from 'public/src/services/schttp'
import { buyer } from './buyer-router'
import { designerListRoutes } from './designer-list'
import { friendshipCouponRoutes } from './friendship-coupon-router'
import { storeRoutes } from './store-comment-router'
import { createStoreFlashSaleRoute } from './store-flash-sale'
import { smsUnsubscribeRoutes } from './sms-unsubscribe-router'
import { setRouterCopy } from 'public/src/pre_requests/utlis'
// 单独购买付费会员
import { buyprimeRoutes } from './buy-prime-router'
// 邮箱解绑
import { emailUnbindRoutes } from './email-unbind-router'
import { infringementComplaintRoutes } from './infringement-complaint-router'
import { userCredentials } from './user-credential'
import { createWishlistSsrRouter } from './wishlist-router'
import { createTrackRoute } from './track-router'
import { createRankProductsRoute } from './rank-products-router'
import { createFlashSaleRoute } from './flash-sale.js'
import { xtraRoutes } from './xtra-router'
import { createBrandZoneRoute } from './brand-zone'
import { createBrandZonePromotionRoute } from './brand-zone-promotion'
import { createTredRoute } from './trend'
import { createUserNewsRoute } from './user-news'
import { triggerAbtRouter } from '@shein-aidc/basis-abt-router'
import { createUserOrderRoute } from './user-order-router'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

// todo: 优化
import { getLang } from 'public/src/pages/user_message_new/getLang.js'

// 滚动顶部
import scrollTop from 'public/src/pages/common/scroll_top.js'
import Vue from 'vue'
import TMG from '@shein/time-management-guru'
import { handleProductListPreRequestBeforeEachRoute } from 'public/src/pre_requests/modules/productList/index.js'
import { preConnect } from '../../../pre_requests/utlis/prefetch'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import {
  handleCategoryRouterTriggerQuickRequestBefore,
  handleCategoryRouterTriggerQuickRequestAfter
} from 'public/src/pre_requests/modules/category/index.js'
import { handlePreSearchBeforeEachRoute } from 'public/src/pre_requests/modules/pre_search.js'
import { preFetchDetailLanguage } from 'public/src/pre_requests/modules/goods_detail.js'
import { loadCSS, scrollReset } from 'public/src/pages/common/utils/index.js'
import dashboardServcie from 'public/src/pages/user/child_pages/dashboard/service/index.js'
import switchColorCache from 'public/src/pages/goods_detail/utils/switchColorCache'
import { createFlashSaleSpecialsRoute } from './flash-sale-specials'
import { createSuperDealsRoute } from './thrifty-find'
import { campaignsRouter } from './campaigns-router'
import { createPayRoute } from './pay-router'
import { isLogin } from 'public/src/pages/common/utils/index.js'
const {
  PUBLIC_CDN,
  langPath,
  tabActive,
  is_include_critical_css,
  CSS_LINK,
  // FIRST_LEVEL_PATH_LIST,
  SiteUID,
  language,
} = gbCommonInfo
// const urlPathList = FIRST_LEVEL_PATH_LIST.map(p => `${langPath}${p}`)
/* eslint-disable no-unused-vars */
window.__webpack_public_path__ = `${PUBLIC_CDN}/pwa_dist/assets/`

//或者加载App.vue里？
//处理路由的component，改成同步加载vue和css文件
const handleRoutes = routes => {
  //return routes
  const newRoutes = routes.map(route => {
    let { component, children = [], meta = {}, ...props } = route

    //component = component
    const newComponent = async () => {
      // 个人中心多css引用
      const { pageType, subPageType } = meta
      let promiseArr = []
      // 落地页(无论是服务器渲染还是客户端渲染), 有经提取的首屏css
      // 落地页是服务器渲染, 没有经提取的首屏css
      if (
        meta.isCssInVue ||
        (tabActive && tabActive == pageType && (is_include_critical_css || tabActive == 'activity'))
      ) {
        //promiseArr = []
      } else {
        //非ssr所有页面默认加载这个css
        const loadCss = []

        // 个人中心多css引用
        /*
        if( pageType && ['userPage', 'ordersPage'].includes(pageType) ){
          loadCss.push(...['my_account', 'checkout', 'activity'])

          if( subPageType == 'userMarketing' ){
            loadCss.push(...['my_account', 'spa-userMarketing_style'])
          }
        } else if( pageType ){
          loadCss.push(...['spa-' + pageType + '_style'])
        }
        */
        if (
          pageType &&
          pageType !== 'userPage' &&
          pageType !== 'ordersPage' &&
          pageType !== 'activity'
        ) {
          loadCss.push(`spa-${pageType}_style`)
        }

        //这里在初始化直接被执行了，但还么走到对应路由
        promiseArr = loadCss.map(name => {
          return loadCSS(CSS_LINK[name], true).catch(err => {
            console.error('资源加载错误：', err)
          })
        })
      }

      //要加载全局样式？对于ssr全局样式会不会有样式闪动问题，先测试看看
      const [comp] = await Promise.all([
        //component放在第一位对应comp取值
        component(),
        ...promiseArr
      ])
      return comp
    }

    if (children && children.length) {
      children = handleRoutes(children)
    }
    return {
      component: newComponent,
      children,
      meta,
      ...props
    }
  })
  return newRoutes
}

/**
 * name 与自有埋点中的pn相同
 */
const routes = handleRoutes([
  createSecondaryHomeRoute(langPath),
  ...detailRouters,
  {
    path: `${langPath}/category`,
    component: prefetchResource.importAsyncComponent({
      chunkName: 'category',
      componentFactory: () =>
        import(/* webpackChunkName: "category" */ '../../category/container.vue')
    }),
    // component: () => import(/* webpackChunkName: "category" */ '../../category/container.vue'),
    name: 'page_category',
    meta: {
      abt: {
        posKeys: [/*'NaviAllTab'*/],
        newPosKeys: []
      },
      keepAlive: true,
      isCssInVue: true,
      pageType: 'category',
      monitorTag: 'category'
    }
  },
  {
    path: '/:any?/campaign/:name_en?',
    name: 'config_activity',
    beforeEnter: (to, from, next) => {
      // 检查 Swiper 是否已加载，如果已加载则直接导航
      if (typeof Swiper === 'undefined' && typeof window !== 'undefined') {
        const script = document.createElement('script')
        script.src = `${gbCommonInfo.PUBLIC_CDN}/pwa_dist/libs/swiper/swiper.min.js` // Swiper 的脚本文件 CDN 资源
        script.onload = next  // 当脚本加载完成后，继续路由导航
        document.body.appendChild(script)
      } else {
        next()
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "config_activity_client" */ '../../config_activity/components/app.vue'
      ),
    meta: {
      keepAlive: true,
      pageType: 'activity',
      monitorTag: 'activity',
      isCssInVue: true
    },
    alias: ['/:any?/act/management/*', '/:any?/act/preview/*']
  },
  {
    path: '/*shop-multiple-sets-*',
    name: 'shop_look',
    meta: {
      pageType: 'shoplook',
      notNeedCommonHeader: false,
      type: 2,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "shop_look" */ '../../shop_look/container.vue')
  },
  {
    path: '/*looks-we-love-*',
    name: 'looks_we_love',
    meta: {
      keepAlive: true,
      pageType: 'shoplook',
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "shop_look" */ '../../shop_look/looks_we_love.vue')
  },
  {
    path: '/*member-image-list-outfit*',
    name: 'loveromwe-index',
    meta: {
      keepAlive: true,
      pageType: 'loveromwe',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () =>
      import(/* webpackChunkName: "loveromwe-index" */ '../../loveromwe/container.vue')
  },
  {
    path: '/*-photo-(\\d+).html',
    name: 'loveromwe-detail',
    meta: {
      pageType: 'loveromwe',
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "loveromwe-detail" */ '../../loveromwe/detail.vue')
  },
  {
    path: `${langPath}/store/following`,
    name: 'StoreFollowing',
    component: () =>
      import(
        /* webpackChunkName: "user-follow-list-page" */ '../../user/child_pages/follow_list/index.vue'
      ),
    meta: {
      // needLogin: true,
      pageType: 'follow_list',
      isCssInVue: true,
      notNeedCommonHeader: true,
      keepAlive: true
      // monitorTag: 'follow_list'
    }
  },
  {
    path: `${langPath}/user`,
    name: 'userPage',
    meta: {
      pageType: 'userPage',
      isCssInVue: true,
      notNeedCommonHeader: true
    },
    children: userRoutes,
    component: () => import(/* webpackChunkName: "user" */ '../../user/container.vue')
  },
  {
    path: `${langPath}/orders`,
    name: 'ordersPage',
    meta: {
      pageType: 'ordersPage',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    children: ordersRoutes,
    component: () => import(/* webpackChunkName: "user" */ '../../user/container.vue')
  },
  {
    path: `${langPath}/user/message`,
    component: () =>
      import(/* webpackChunkName: "user_message" */ '../../user_message_new/index.vue'),
    meta: {
      isCssInVue: true
    },
    beforeEnter: (to, from, next) => {
      getLang(langPath, data => {
        window.vBus.GB_lang_message = data
        next()
      })
    },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "user_message_index" */ '../../user_message_new/mes-index.vue'
          ),
        meta: {
          isCssInVue: true,
          pageType: 'user_message',
          notNeedCommonHeader: true,
          monitorTag: 'user-message'
        },
        name: 'message'
      },
      {
        path: 'detail',
        component: () =>
          import(
            /* webpackChunkName: "user_message_detail" */ '../../user_message_new/mes-detail.vue'
          ),
        meta: {
          isCssInVue: true,
          pageType: 'user_message',
          notNeedCommonHeader: true
        },
        name: 'detail'
      }
    ]
  },

  {
    path: `${langPath}/pay/cod`,
    name: 'payCod',
    meta: {
      // keepAlive: true,
      pageType: 'payCod',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "payCod_spa" */ '../../cod/index.vue')
  },
  {
    path: `${langPath}/pay/konbini`,
    name: 'payKonbini',
    meta: {
      // keepAlive: true,
      pageType: 'payKonbini',
      payMethod: 'adyen-konbini',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "payKonbini_spa" */ '../../konbini/index.vue')
  },
  {
    path: `${langPath}/pay/mtbc`,
    name: 'payMtbc',
    meta: {
      // keepAlive: true,
      pageType: 'payKonbini', //和konbini样式一致
      payMethod: 'adyen-multibanco',
      notNeedCommonHeader: true
    },
    component: () => import(/* webpackChunkName: "payMultibanco_spa" */ '../../konbini/index.vue')
  },
  {
    path: `${langPath}/transit`,
    name: 'transit',
    meta: {
      keepAlive: false,
      notNeedCommonHeader: true
    },
    component: () => import(/* webpackChunkName: "transit_spa" */ '../../transit/index.vue')
  },
  {
    path: `${langPath}/student-verification`,
    name: 'student-verification',
    meta: {
      navTitle: language.SHEIN_KEY_PWA_20429,
      notNeedCommonHeader: true
    },
    component: () =>
      import(/* webpackChunkName: "user" */ '../../student_verification_new/container.vue')
  },
  campaignsRouter,
  // {
  //   path: '/:any?/campaigns/:identity?',
  //   name: 'campaigns',
  //   component: prefetchResource.importAsyncComponent({
  //     chunkName: 'ccc-campaigns',
  //     componentFactory: () =>
  //       import(/* webpackChunkName: "ccc-campaigns" */ '../../ccc-campaigns/App.vue')
  //   }),
  //   meta: {
  //     keepAlive: true,
  //     isCssInVue: true,
  //     pageType: 'campaigns',
  //     monitorTag: 'campaigns'
  //   },
  //   alias: [...urlPathList]
  // },
  {
    path: `${langPath}/user/(auth/)?login`,
    name: 'login',
    meta: {
      keepAlive: true,
      isCssInVue: true,
      pageType: 'login',
      notNeedCommonHeader: true
    },
    component: () => import(/* webpackChunkName: "login-page" */ '../../login/serverIndex.vue')
  },
  {
    path: `${langPath}/flash-sale-hook-product.html`,
    name: 'flash_sale_hook_product',
    meta: {
      keepAlive: true,
      pageType: 'flash_sale_hook_product',
      monitorTag: 'flash-sale-hook-product',
      isCssInVue: true
    },
    component: () =>
      import(
        /* webpackChunkName: "flash_sale_hook_product" */ '../../flash-sale-hook-product/container.vue'
      )
  },
  createRankProductsRoute(langPath),
  createPicksRoute(langPath),
  createListRoute(langPath),
  createStoreRoute(langPath),
  createStoreFlashSaleRoute(langPath),
  createAdvertisingRoute(langPath),
  ...createWishlistSsrRouter(langPath),
  createUnderPricesListRoute(langPath),
  createFlashSaleRoute(langPath),
  createFlashSaleSpecialsRoute(langPath),
  createSuperDealsRoute(langPath),
  ...createPickedInfoListRoute(langPath),
  createDiscountChannelListRoute(langPath),
  ...createArticleRoute(langPath),
  createTrackRoute(langPath),
  createBrandZoneRoute(langPath),
  createBrandZonePromotionRoute(langPath),
  ...createTredRoute(langPath),
  createUserNewsRoute(langPath),
  ...createPayRoute(langPath),
  ...createUserOrderRoute(langPath),
  ...couponProductRoutes,
  ...preSearchRoutes,
  ...freeTrialRoutes,
  ...giftcardRoutes,
  ...checkoutRoutes,
  ...cartRoutes,
  ...lookbookRoutes,
  ...robotRoutes,
  ...faqRoutes,
  ...shareEarnRoutes,
  ...liveRoutes,
  ...sheinxRoutes,
  ...buyer,
  ...designerListRoutes,
  ...friendshipCouponRoutes,
  ...storeRoutes,
  ...smsUnsubscribeRoutes,
  ...buyprimeRoutes,
  ...emailUnbindRoutes,
  ...userCredentials,
  ...infringementComplaintRoutes,
  ...xtraRoutes,
  homeRouter,
  {
    path: '*',
    redirect(to) {
      console.log(to)
      location.href = `${langPath}/404.html?from=${encodeURIComponent(location.href)}`
    }
  }
])

/**
 * 只在客户端编译执行
 * @param {客户端路由对象} param0
 */
function clientFunc({ router }) {
  /* extent */
  Vue.prototype.$routerPush = function ({ name = '', params = {}, query = {} } = {}) {
    if (arguments.length <= 0) return
    let info = null
    info =
      typeof arguments[0] === 'string'
        ? arguments[0]
        : { name: name, params: params, query: Object.assign({}, query) }

    console.log(info)
    router.push(info)
  }

  Vue.prototype.$routerReplace = function ({ name = '', params = {}, query = {} } = {}) {
    if (arguments.length <= 0) return
    let info = null
    info =
      typeof arguments[0] === 'string'
        ? arguments[0]
        : { name: name, params: params, query: Object.assign({}, query) }
    router.replace(info)
  }

  Vue.prototype.$routerGoBack = function ({ n = -1 } = {}) {
    router.go(n)
  }

  Vue.prototype.$routerGoWish = function (query = {}, isHref) {
    const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'common/towishlist' })
    // 跳转收藏，统一拦截处理付费会员入口标识
    const expirationTime = localStorage.getItem(
      `wish_shein_club_key_${memberId}`
    )
    if (expirationTime && Date.now() < expirationTime) {
      query.cpm = 1 // 还没有过期， 添加标识
    }
    if (isHref) {
      location.href = `${location.origin}${langPath}/wishlist?pf=${query.pf}&cpm=${query.cpm || ''}`
    } else {
      router.push({ path: `${langPath}/wishlist`, query })
    }
  }

  Vue.prototype.$historyCon = new (class {
    state = []
    fromKey = 0
    direction = 0

    get currentKey() {
      return Number(history.state.key)
    }

    beforeEach(to, from) {
      const toKey = this.currentKey
      this.direction =
        from.meta.forwardFrom || !this.fromKey || this.fromKey === toKey
          ? 0
          : this.fromKey < toKey
            ? 1
            : -1
      window.appRouteExtData.direction = this.direction
    }

    async afterEach(to, from) {
      await Vue.nextTick()
      if (from.meta.forwardFrom || (this.direction === 0 && this.fromKey !== this.currentKey)) {
        const currentIndex = this.state.indexOf(from.meta.forwardFrom || this.fromKey) + 1
        this.state.splice(currentIndex, this.state.length, this.currentKey)
        if (from.meta.forwardFrom) delete from.meta.forwardFrom
      } else if (this.state.indexOf(this.currentKey) === -1) {
        this.state = [this.currentKey]
      }
      this.fromKey = this.currentKey
      // console.log(this.state)
    }

    jumpTo(key) {
      const ton = this.state.indexOf(key)
      const fromn = this.state.indexOf(this.fromKey)
      if (ton === -1 || fromn === -1 || ton - fromn === 0) return false
      router.go(ton - fromn)
      return true
    }
  })()

  const get_css_then_next = function (pageType, from, next, to) {
    //全部都直接走next，逻辑改到同步加载css和路由组件
    next()
    return
  }

  const goPage = function (to, from, next) {
    //test-TODO
    // window.testTime = { start: +new Date() }

    // for click effect && analysis send
    if (from.meta.isForward) {
      from.meta.isForward = undefined
      get_css_then_next(to.meta.pageType, from, next, to)
    } else {
      get_css_then_next(to.meta.pageType, from, next, to)
    }
  }

  const pointMigrate = async function (name) {
    // 新站点重开站点积分迁移
    try {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const { Movepoints } = await abtservice.getUserAbtResult({ posKeys: 'Movepoints' })
      const { param } = Movepoints
      if (
        ['config_index', 'UserIndex', 'Points', 'PrevPoints', 'checkout'].includes(name) &&
        param == 'type=B'
      ) {
        if (isLogin()) {
          const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'common/towishlist' }) || ''
          let pointMigrateFlag = localStorage.getItem(`${member_id}_${SiteUID}_pointMigrateFlag`)
            ? JSON.parse(localStorage.getItem(`${member_id}_${SiteUID}_pointMigrateFlag`))
            : {}
          let nowTime = new Date().getTime()
          let flagSetTime = pointMigrateFlag ? pointMigrateFlag.time : ''

          if (
            pointMigrateFlag?.status == 'error' &&
            flagSetTime &&
            nowTime - flagSetTime > 600000
          ) {
            // 接收到积分迁移失败标识符，再次发送迁移请求
            schttp({ url: '/autoMigrate' })
              .then(res => {
                if (res.code == 0 && res.info) {
                  if (res.info?.result != 1) {
                    // 迁移失败，本地新增标志位记录调用失败
                    localStorage.setItem(
                      `${member_id}_${SiteUID}_pointMigrateFlag`,
                      JSON.stringify({ status: 'error', time: new Date().getTime() })
                    )
                  } else {
                    localStorage.setItem(
                      `${member_id}_${SiteUID}_pointMigrateFlag`,
                      JSON.stringify({ status: 'success', time: new Date().getTime() })
                    )
                  }
                } else {
                  localStorage.setItem(
                    `${member_id}_${SiteUID}_pointMigrateFlag`,
                    JSON.stringify({ status: 'error', time: new Date().getTime() })
                  )
                }
              })
              .catch(() => {
                localStorage.setItem(
                  `${member_id}_${SiteUID}_pointMigrateFlag`,
                  JSON.stringify({ status: 'error', time: new Date().getTime() })
                )
              })
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * 路由切换中
   */
  const onRouterSwitch = function ({ switching = false }) {
    router.switching = switching
  }
  const getUserInfo = cb => {
    //加载落地页（不管有没登录），首次获取userInfo情况
    const firstFetch = !gbCommonInfo.user || !Object.keys(gbCommonInfo.user).length
    if (firstFetch) {
      gbCommonInfo.user = gbCommonInfo.user || {}
      schttp({
        url: '/api/user/center/fetchUserInfo/get',
        params: {
          date: +new Date()
        }
      }).then(res => {
        gbCommonInfo.user = res.user
        //如果未登录，设置了email的key
        gbCommonInfo.user.email = res?.user?.email ? '1' : ''

        cb && cb()
      })
    }
  }

  // 路由进入
  router.beforeEach((to, from, next) => {
    preConnect()
    onRouterSwitch({ switching: true })
    if (!to.name == from.name) {
      scrollReset()
    }

    // https://github.com/vuejs/vue-router/issues/2571
    if (!history.state) {
      history.replaceState({ key: Date.now() }, to.name, location.href)
    }

    Vue.prototype.$historyCon.beforeEach(to, from)

    if (from.path != '/' && from.path != '/campaigns') {
      window.previousUrl = langPath + from.path
    }

    window.previousPageType = from.meta.pageType

    // 当路由有嵌套时（即children），beforeRouteEnter根据嵌套数会多次触发，设置变量以免多次触发
    window.hasEnterRoute = 1 // 设置成有值，区分开undefined的判断
    window.hasLeaveRoute = 1 // 设置成有值，区分开undefined的判断

    scrollTop.clear()

    triggerAbtRouter({ routeName: to.name })

    // 个人中心处理
    if (['userPage', 'deactivateAccount', 'ordersPage'].includes(to.meta.pageType)) {
      if (!gbCommonInfo.user || !Object.keys(gbCommonInfo.user).length) {
        getUserInfo(() => {
          goPage(to, from, next)
          pointMigrate(to.name)
          appEventCenter.$emit('loginLoad')
        })

        // })
        // }
        // 该方法在iphone上有问题
        // (async () => {
        // 	await fetchUserInfo()
        // })()
      } else {
        goPage(to, from, next)
        pointMigrate(to.name)
      }
    } else if (to.meta.pageType === 'campaigns') {
      getUserInfo(() => {
        pointMigrate(to.name)
      })
      // 不是路由跳转的 直接通过 由服务端判断是否是一级路径；
      // 专题如果是一级路径的 直接通过
      if (!from.name || !to.params?.identity) {
        goPage(to, from, next)
        pointMigrate(to.name)
      } else {
        // 否则 去检查专题是否存在绑定的一级路径
        let tinyUrlList = []
        try {
          const tinyUrlListCache = localStorage.getItem('campaigns_tiny_url_list')
          const data = tinyUrlListCache && JSON.parse(tinyUrlListCache)
          tinyUrlList = data?.value || []
        } catch (e) {
          console.log(e)
        }
        const identity = `${to.params.identity || ''}`.split('*')?.[0]?.toLowerCase()
        const path = tinyUrlList?.filter(i => i.identity === identity)?.[0]?.tinyUrl
        if (path) {
          next({ path: `${langPath}/${path}`, query: to.query })
          pointMigrate(to.name)
        } else {
          goPage(to, from, next)
          pointMigrate(to.name)
        }
      }
    } else {
      // 其他页面异步请求  ？？？为什么把获取用户信息这一步加在这里
      getUserInfo()
      // 仅客户端页面跳转时需要预加载
      if (to.name === 'page_goods_detail' && from.name && to?.params?.[1] !== from?.params?.[1]) {
        // 商详预加载接口引来 URL 入参,此时不能需要从 to 中获取, location 还没有变更为商详 URL
        setRouterCopy(to)
        // 商详接口预请求
        const isSwitchColor = to.query?.isFromSwitchColor
        const goods_id = to?.params?.[1]
        const hasCache = switchColorCache.get(goods_id, 'cold')
        if (!(isSwitchColor && hasCache)) {
          // 当商详来自切换色块并且有缓存时不触发预请求
          TMG.triggerQuickRequests('pageGoodsDetail', {
            quickRequestNames: [`getGoodsDetail/${goods_id}`, `getGoodsDetailRealTime/${goods_id}`],
            triggerParams: { from: 'detail' },
            throttle: true
          })
        }
      }

      // 列表页 spa 跳转接口预加载, 并且不能是列表页内部跳转 (如翻页, 筛选等)
      handleProductListPreRequestBeforeEachRoute(to, from)

      // 分类页接口预取
      if (to.name === 'page_category') {
        handleCategoryRouterTriggerQuickRequestBefore(to, from)
      }

      // 预搜索页接口预取
      handlePreSearchBeforeEachRoute(to, from)

      goPage(to, from, next)
      pointMigrate(to.name)
    }
  })

  router.afterEach((to, from) => {
    onRouterSwitch({ switching: false })
    Vue.prototype.$historyCon.afterEach(to, from)

    // 专题活动浏览任务页面倒计时提示
    // 待专题抽奖和游戏都接入任务中心之后删掉旧的act_browse_tips和act_add_cart_tips
    if (
      to.query &&
      to.query.actUrlType &&
      [
        'gameBrowse',
        'collectionBrowse',
        'petBrowse',
        'actLuckyGame',
        'moveUpBrowse',
        'tapandwin'
      ].includes(to.query.actUrlType)
    ) {
      if (typeof window.gbActivityBrowseTips !== 'undefined') {
        window.gbActivityBrowseTips.$children[0].init()
      } else {
        import(/* webpackChunkName: "act_browse_tips" */ '../../common/act_browse_tips')
      }
    } else {
      if (typeof window.gbActivityBrowseTips !== 'undefined') {
        window.gbActivityBrowseTips.$children[0].show = false
        if (window.gbActivityBrowseTips.$children[0].timer) {
          clearInterval(window.gbActivityBrowseTips.$children[0].timer)
        }
      }
    }
    // 加车任务计数
    if (
      to.query &&
      to.query.actUrlType &&
      ['collectionAddCart', 'tapandwinAddCart', 'actLuckyGameAddCart'].includes(to.query.actUrlType)
    ) {
      if (typeof window.gbActivityAddCartTips !== 'undefined') {
        window.gbActivityAddCartTips.$children[0].init()
      } else {
        import(/* webpackChunkName: "act_add_cart_tips" */ '../../common/act_add_cart_tips')
      }
    } else {
      if (typeof window.gbActivityAddCartTips !== 'undefined') {
        window.gbActivityAddCartTips.$children[0].show = false
        if (window.gbActivityAddCartTips.$children[0].timer) {
          clearInterval(window.gbActivityAddCartTips.$children[0].timer)
        }
      }
    }

    // 任务中心浏览倒计时 & 加车进度条新样式
    if (to.query && to.query.actTaskType) {
      if (to.query.actTaskType == 'browse') {
        // 浏览倒计时
        if (typeof window.gbTaskCenterBrowseTips !== 'undefined') {
          window.gbTaskCenterBrowseTips.$children[0].init()
        } else {
          import(/* webpackChunkName: "TaskCenterBrowse" */ '../../common/task-center-tips/browse')
        }
      } else if (to.query.actTaskType == 'addCart') {
        // 加车进度条
        if (typeof window.gbTaskCenterAddCartTips !== 'undefined') {
          window.gbTaskCenterAddCartTips.$children[0].init()
        } else {
          import(
            /* webpackChunkName: "TaskCenterAddCart" */ '../../common/task-center-tips/add-cart'
          )
        }
      }
    } else {
      if (typeof window.gbTaskCenterBrowseTips !== 'undefined') {
        window.gbTaskCenterBrowseTips.$children[0].show = false
        if (window.gbTaskCenterBrowseTips.$children[0].timer)
          clearInterval(window.gbTaskCenterBrowseTips.$children[0].timer)
      }
      if (typeof window.gbTaskCenterAddCartTips !== 'undefined') {
        window.gbTaskCenterAddCartTips.$children[0].show = false
      }
    }

    handleCategoryRouterTriggerQuickRequestAfter(to, from)
    preFetchDetailLanguage(to, from)
    dashboardServcie.triggerRouterAfterEachPrefetch(to, from)
  })

  router.onError(() => {
    if (typeof navigator !== 'undefined' && !navigator.onLine) {
      Toast(gbCommonInfo.language.SHEIN_KEY_PWA_31762 || 'Please connect to a network')
    }
  })
}

export { routes, clientFunc }
